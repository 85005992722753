<template>
  <div class="book-detail">
    <detail-title
      @back="back"
      :showShelf="true"
      ref="title"
    ></detail-title>
    <scroll
      class="content-wrapper"
      :top="42"
      :bottom="52"
      @onScroll="onScroll"
      ref="scroll"
    >
      <book-info
        :cover="cover"
        :title="title"
        :author="author"
        :desc="desc"
      ></book-info>
      <!-- 版权、出版社、分类、语言、isbn国际标准书号 -->
      <div class="book-detail-content-wrapper">
        <div class="book-detail-content-title">{{$t('detail.copyright')}}</div>
        <div class="book-detail-content-list-wrapper">
          <div class="book-detail-content-row">
            <div class="book-detail-content-label">{{$t('detail.publisher')}}</div>
            <div class="book-detail-content-text">{{publisher}}</div>
          </div>
          <div class="book-detail-content-row">
            <div class="book-detail-content-label">{{$t('detail.category')}}</div>
            <div class="book-detail-content-text">{{categoryText}}</div>
          </div>
          <div class="book-detail-content-row">
            <div class="book-detail-content-label">{{$t('detail.lang')}}</div>
            <div class="book-detail-content-text">{{lang}}</div>
          </div>
          <div class="book-detail-content-row">
            <div class="book-detail-content-label">{{$t('detail.ISBN')}}</div>
            <div class="book-detail-content-text">{{isbn}}</div>
          </div>
        </div>
      </div>
      <!-- 目录 -->
      <div class="book-detail-content-wrapper">
        <div class="book-detail-content-title">{{$t('detail.navigation')}}</div>
        <div class="book-detail-content-list-wrapper">
          <div
            class="loading-text-wrapper"
            v-if="!this.navigation"
          >
            <span class="loading-text">{{$t('detail.loading')}}</span>
          </div>
          <div class="book-detail-content-item-wrapper">
            <div
              class="book-detail-content-item"
              v-for="(item, index) in flatNavigation"
              :key="index"
              @click="read(item)"
            >
              <div
                class="book-detail-content-navigation-text"
                :class="{'is-sub': item.deep> 1}"
                :style="itemStyle(item)"
                v-if="item.label"
              >{{item.label}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 试读 -->
      <div class="book-detail-content-wrapper">
        <div class="book-detail-content-title">{{$t('detail.trial')}}</div>
        <div class="book-detail-content-list-wrapper">
          <div
            class="loading-text-wrapper"
            v-if="!this.displayed"
          >
            <span class="loading-text">{{$t('detail.loading')}}</span>
          </div>
        </div>
        <div
          id="preview"
          v-show="this.displayed"
          ref="preview"
        ></div>
      </div>
    </scroll>
    <!-- 底部的阅读、听书、加入书架 -->
    <div class="bottom-wrapper">
      <div
        class="bottom-btn"
        @click.stop.prevent="readBook()"
      >{{$t('detail.read')}}</div>
      <div
        class="bottom-btn"
        @click.stop.prevent="trialListening()"
      >{{$t('detail.listen')}}</div>
      <div
        class="bottom-btn"
        @click.stop.prevent="addOrRemoveShelf()"
      >
        <span
          class="icon-check"
          v-if="inBookShelf"
        ></span>
        {{inBookShelf ? $t('detail.isAddedToShelf') : $t('detail.addOrRemoveShelf')}}
      </div>
    </div>
    <toast
      :text="toastText"
      ref="toast"
    ></toast>
  </div>
</template>

<script type="text/ecmascript-6">
import DetailTitle from '../../components/detail/DetaiTitle'
import BookInfo from '../../components/detail/BookInfo'
import Scroll from '../../components/common/Scroll'
import Toast from '../../components/common/Toast'
import { getDetail as detail } from '../../api/store'
import { px2rem, realPx } from '../../utils/utils'
import { addToShelf, removeFromBookShelf } from '../../utils/store'
import Epub from 'epubjs'
import { getLocalForage } from '../../utils/localForage'
import { saveBookShelf, saveCover } from '../../utils/localStorage'
import { storeShelfMixin } from '../../utils/mixin'
global.ePub = Epub
export default {
  mixins: [storeShelfMixin],
  components: {
    DetailTitle,
    Scroll,
    BookInfo,
    Toast
  },
  computed: {
    desc () {
      if (this.description) {
        return this.description.length > 150 ? this.description.substring(0, 100) : this.description
      } else {
        return ''
      }
    },
    flatNavigation () {
      if (this.navigation) {
        return Array.prototype.concat.apply([], Array.prototype.concat.apply([], this.doFlatNavigation(this.navigation.toc)))
      } else {
        return []
      }
    },
    lang () {
      return this.metadata ? (this.metadata.language ? this.metadata.language : this.bookItem.language) : '-'
    },
    isbn () {
      return this.metadata ? (this.metadata.identifier ? this.metadata.identifier : this.bookItem.identifier) : '-'
    },
    publisher () {
      return this.metadata ? (this.metadata.publisher ? this.metadata.publisher : this.bookItem.publisher) : '-'
    },
    title () {
      return this.metadata ? (this.metadata.title ? this.metadata.title : this.bookItem.fileName) : ''
    },
    author () {
      return this.metadata ? (this.metadata.creator ? this.metadata.creator : this.bookItem.creator) : ''
    },
    inBookShelf () {
      // BUG 有问题，刷新你一下会变
      if (this.bookItem && this.shelfList) {
        const flatShelf = (function flatten (arr) {
          return [].concat(...arr.map(v => v.itemList ? [v, ...flatten(v.itemList)] : v))
        })(this.shelfList).filter(item => item.type === 1)
        const book = flatShelf.filter(item => item.fileName === this.bookItem.fileName)
        return book && book.length > 0
      } else {
        return false
      }
    }
  },
  data () {
    return {
      bookItem: null,
      book: null,
      metadata: null,
      trialRead: null,
      cover: null,
      navigation: null,
      displayed: false,
      audio: null,
      randomLocation: null,
      description: null,
      toastText: '',
      trialText: null,
      categoryText: null,
      opf: null
    }
  },
  methods: {
    // 加入或移出书架
    addOrRemoveShelf () {
      if (this.inBookShelf) {
        console.log(this.bookItem)
        this.setShelfList(removeFromBookShelf(this.bookItem)).then(() => {
          saveBookShelf(this.shelfList)
        })
      } else {
        console.log('123')
        addToShelf(this.bookItem)
        this.getShelfList()
      }
    },
    showToast (text) {
      this.toastText = text
      this.$refs.toast.show()
    },
    readBook () {
      this.$router.push({
        path: `/ebook/${this.categoryText}|${this.fileName}`
      })
    },
    trialListening () {
      getLocalForage(this.bookItem.fileName, (err, blob) => {
        if (!err && blob && blob instanceof Blob) {
          // 缓存中有数据直接读取
          this.$router.push({
            path: '/store/speaking',
            query: {
              fileName: this.bookItem.fileName,
              categoryText: this.categoryText,
              inBookShelf: this.inBookShelf
            }
          })
        } else {
          // 没有缓存数据，在线获取
          this.$router.push({
            path: '/store/speaking',
            query: {
              fileName: this.bookItem.fileName,
              categoryText: this.categoryText,
              opf: this.opf
            }
          })
        }
      })
    },
    read (item) {
      this.$router.push({
        path: `/ebook/${this.categoryText}|${this.fileName}`
      })
    },
    itemStyle (item) {
      return {
        paddingLeft: (item.deep - 1) * px2rem(20) + 'rem',
        boxSizing: 'border-box'
      }
    },
    doFlatNavigation (content, deep = 1) {
      const arr = []
      content.forEach(item => {
        item.deep = deep
        arr.push(item)
        if (item.subitems && item.subitems.length > 0) {
          arr.push(this.doFlatNavigation(item.subitems, deep + 1))
        }
      })
      return arr
    },
    downloadBook () {
      const opf = `${process.env.VUE_APP_EPUB_URL}/${this.bookItem.categoryText}/${this.bookItem.fileName}/OEBPS/package.opf`
      this.parseBook(opf)
    },
    parseBook (url) {
      this.book = new Epub(url)
      this.book.loaded.metadata.then(metadata => {
        this.metadata = metadata
      })
      this.book.loaded.navigation.then(nav => {
        this.navigation = nav
        console.log(nav)
        if (this.navigation.toc && this.navigation.toc.length > 1) {
          const herf = (this.navigation.toc[1].href.indexOf('OPS/') !== -1) ? this.navigation.toc[1].href.replace('OPS/', '') : this.navigation.toc[1].href
          const candisplay = this.display(herf)
          console.log(candisplay)
          if (candisplay) {
            candisplay.then(section => {
              if (this.$refs.scroll) {
                this.$refs.scroll.refresh()
              }
              this.displayed = true
              const reg2 = new RegExp('<.+?>', 'g')
              const reg = new RegExp('(?<=<title>).+(?=</title>)', 'g')
              const reg3 = new RegExp('@page { margin-bottom: 5.000000pt; margin-top: 5.000000pt; }', 'g')
              const text = section.output.replace(reg, '').replace(reg3, '').replace(reg2, '').replace(/\s\s/g, '')
              this.description = (this.bookItem.title && (this.bookItem.title.length > 10)) ? this.bookItem.title : text
              // console.log(this.description)
            }).catch(err => {
              console.log('获取不到section', err)
            })
          }
        }
      })
    },
    init () {
      this.fileName = this.$route.query.fileName
      this.categoryText = this.$route.query.category
      if (this.fileName) {
        detail({
          fileName: this.fileName
        }).then(response => {
          if (response.status === 200 && response.data.error_code === 0 && response.data.data) {
            const data = response.data.data
            this.bookItem = data
            this.cover = this.bookItem.cover
            // 保存封面
            saveCover(this.fileName, this.bookItem.cover)
            let rootFile = data.rootFile
            if (rootFile.startsWith('/')) {
              rootFile = rootFile.substring(1, rootFile.length)
            }
            this.opf = `${process.env.VUE_APP_EPUB_OPF_URL}/${this.fileName}/${rootFile}`
            this.parseBook(this.opf)
          } else {
            this.showToast(response.data.msg)
          }
        })
      }
    },
    back () {
      this.$router.go(-1)
    },
    display (location) {
      if (this.$refs.preview) {
        if (!this.rendition) {
          this.rendition = this.book.renderTo('preview', {
            width: window.innerWidth > 640 ? 640 : window.innerWidth,
            height: window.innerHeight,
            method: 'default'
          })
        }
        if (!location) {
          return this.rendition.display()
        } else {
          return this.rendition.display(location)
        }
      }
    },
    onScroll (offsetY) {
      if (offsetY > realPx(42)) {
        this.$refs.title.showShadow()
      } else {
        this.$refs.title.hideShadow()
      }
    }
  },
  mounted () {
    this.init()
    if (!this.shelfList || this.shelfList.length === 0) {
      this.getShelfList()
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
@import '../../assets/styles/global';
.book-detail {
  width: 100%;
  background: white;
  .content-wrapper {
    width: 100%;
    .book-detail-content-wrapper {
      width: 100%;
      border-bottom: px2rem(1) solid #eee;
      box-sizing: border-box;
      .book-detail-content-title {
        font-size: px2rem(20);
        font-weight: bold;
        padding: px2rem(20) px2rem(15) px2rem(10) px2rem(15);
        box-sizing: border-box;
      }
      .book-detail-content-list-wrapper {
        padding: px2rem(10) px2rem(15);
        box-sizing: border-box;
        .loading-text-wrapper {
          width: 100%;
          .loading-text {
            font-size: px2rem(14);
            color: #666;
          }
        }
        .book-detail-content-row {
          display: flex;
          box-sizing: border-box;
          margin-bottom: px2rem(10);
          .book-detail-content-label {
            flex: 0 0 px2rem(70);
            font-size: px2rem(14);
            color: #666;
          }
          .book-detail-content-text {
            flex: 1;
            font-size: px2rem(14);
            color: #333;
          }
        }
        #preview {
        }
        .book-detail-content-item-wrapper {
          .book-detail-content-item {
            padding: px2rem(15) 0;
            font-size: px2rem(14);
            line-height: px2rem(16);
            color: #666;
            border-bottom: px2rem(1) solid #eee;
            &:last-child {
              border-bottom: none;
            }
            .book-detail-content-navigation-text {
              width: 100%;
              @include ellipsis;
              &.is-sub {
                color: #666;
              }
            }
          }
        }
      }
    }
    .audio-wrapper {
      width: 100%;
      padding: px2rem(15);
      box-sizing: border-box;
      #audio {
        width: 100%;
      }
    }
  }
  .bottom-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 120;
    display: flex;
    width: 100%;
    height: px2rem(52);
    box-shadow: 0 px2rem(-2) px2rem(2) rgba(0, 0, 0, 0.1);
    .bottom-btn {
      flex: 1;
      color: $color-blue;
      font-weight: bold;
      font-size: px2rem(14);
      @include center;
      &:active {
        color: $color-blue-transparent;
      }
      .iconyes {
        margin-right: px2rem(5);
        font-size: px2rem(16);
        color: $color-blue;
      }
    }
    &.hide-shadow {
      box-shadow: none;
    }
  }
}
</style>
